export const usCountryList: string[] = ['US', 'CA', 'PR']

export const europeCountryList: string[] = [
    'DE',
    'IE',
    'PL',
    'UK',
  ]
// List of supported locales for the application
export const localeList: string[] = [
  'en-us', 
  'en-gb', 
  'fr-ca', 
  'en-ca', 
  'en-pr', 
  'en-uk', 
  'en-de', 
  'en-ie', 
  'en-pl'
]